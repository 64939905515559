import { debounce } from 'lodash';

export * from './debug';
export * from './inertia';
export * from './strings';
export * from './array';
export * from './forms';

export const bool = (value) =>
    typeof value === 'string' ? value.toLowerCase() === 'true' : !!value;

export const func = (value) => {
    if (typeof value === 'function') {
        return value;
    }

    return () => value;
};

export const getPage = (index, perPage) => {
    return Math.floor(index / perPage) + 1;
};

export default function asyncDebounce(func, wait) {
    // thanks to https://github.com/lodash/lodash/issues/4815#issuecomment-859379489
    const debounced = debounce(async (resolve, reject, bindSelf, args) => {
        try {
            const result = await func.bind(bindSelf)(...args);
            resolve(result);
        } catch (error) {
            reject(error);
        }
    }, wait);

    // This is the function that will be bound by the caller, so it must contain the `function` keyword.
    function returnFunc(...args) {
        return new Promise((resolve, reject) => {
            debounced(resolve, reject, this, args);
        });
    }

    return returnFunc;
}
